import React, { useState } from 'react';
import styles from './Chat.module.css';

interface Message {
  id: number;
  text: string;
  type: 'ai' | 'user';
}

export const Chat: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([
    {
      id: 1,
      text: 'Hello! I can help you modify and analyze these files. Try giving me commands to update the content or styling.',
      type: 'ai'
    },
    {
      id: 2,
      text: 'Change company from L&P to Salesforce',
      type: 'user'
    },
    {
      id: 3,
      text: 'I\'ve updated the company name from L&P to Salesforce in slide 3 and sheet 1. The changes are now reflected in the preview.',
      type: 'ai'
    }
  ]);
  const [input, setInput] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    // Add user message
    const newMessage: Message = {
      id: messages.length + 1,
      text: input,
      type: 'user'
    };
    setMessages([...messages, newMessage]);
    setInput('');

    // Simulate AI response
    setTimeout(() => {
      const aiResponse: Message = {
        id: messages.length + 2,
        text: 'I understand your request. Let me process that for you.',
        type: 'ai'
      };
      setMessages(prev => [...prev, aiResponse]);
    }, 1000);
  };

  return (
    <>
      <div className={styles.chatMessages}>
        {messages.map(message => (
          <div
            key={message.id}
            className={`${styles.message} ${message.type === 'ai' ? styles.ai : styles.user}`}
          >
            {message.text}
          </div>
        ))}
      </div>
      <div className={styles.commandInput}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <input
            type="text"
            className={styles.input}
            placeholder="Type your command..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <button type="submit" className={styles.sendBtn}>
            <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.5.5 0 0 1-.9.1l-2.79-3.487-3.487 2.79a.5.5 0 0 1-.8-.4v-2.5a.5.5 0 0 1 .1-.9l14.547-5.819a.5.5 0 0 1 .54.11z"/>
            </svg>
          </button>
        </form>
      </div>
    </>
  );
};
