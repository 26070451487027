import React from 'react';
import styles from './Preview.module.css';

export const Preview: React.FC = () => {
  return (
    <div className={styles.previewArea}>
      <div className={styles.previewSection}>
        <h2>PowerPoint Preview</h2>
        <div className={styles.previewContent}>
          {/* Preview content would go here */}
        </div>
        <div className={styles.navigation}>
          <button className={styles.navBtn}>← Previous</button>
          <span className={styles.slideInfo}>Slide 3 of 5</span>
          <button className={styles.navBtn}>Next →</button>
        </div>
      </div>
      <div className={styles.previewSection}>
        <h2>Excel Preview</h2>
        <div className={styles.previewContent}>
          {/* Preview content would go here */}
        </div>
        <div className={styles.navigation}>
          <button className={styles.navBtn}>← Previous</button>
          <span className={styles.slideInfo}>Sheet 4 of 5</span>
          <button className={styles.navBtn}>Next →</button>
        </div>
      </div>
    </div>
  );
};
