import React from 'react';
import styles from './FilePreview.module.css';
import { Chat } from '../Chat/Chat';
import { Preview } from '../Preview/Preview';

// Suggested component structure:
/*
Components:
- FilePreview (main container)
  - Header
    - Title
    - ExportButton
  - MainContent
    - Sidebar
      - ChatMessages
        - Message
      - CommandInput
    - PreviewArea
      - PowerPointPreview
        - NavigationControls
      - ExcelPreview
        - NavigationControls
*/

export const FilePreview: React.FC = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>PowerPoint LLM</h1>
        <button className={styles.exportBtn}>
          <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
          </svg>
          Export Files
        </button>
      </header>
      <div className={styles.mainContent}>
        <div className={styles.sidebar}>
          <Chat />
        </div>
        <Preview />
      </div>
    </div>
  );
};
